export enum Account {
  ADD_ADDRESS = 'Account.ADD_ADDRESS',
  ADD_CREDIT_CARD = 'Account.ADD_CREDIT_CARD',
  ADD_CREDIT_CARD_ERROR = 'Account.ADD_CREDIT_CARD_ERROR',
  CANCEL_ORDER = 'Account.CANCEL_ORDER',
  CHECK_RETURN_STATUS = 'Account.CHECK_RETURN_STATUS',
  DISABLE_ADDRESS = 'Account.DISABLE_ADDRESS',
  DISABLE_PAYMENT_METHOD = 'Account.DISABLE_PAYMENT_METHOD',
  INVITE_COPY_CODE_CLICK = 'Account.INVITE_COPY_CODE_CLICK',
  INVITE_SMS_CLICK = 'Account.INVITE_SMS_CLICK',
  OPEN_ADDRESS_MODAL = 'Account.OPEN_ADDRESS_MODAL',
  OPEN_CREDIT_CARD_MODAL = 'Account.OPEN_CREDIT_CARD_MODAL',
  PAGE_VIEW = 'Account.PAGE_VIEW',
  RETURNS_FAQ_CLICK = 'Account.RETURNS_FAQ_CLICK',
  SET_PRIMARY_ADDRESS = 'Account.SET_PRIMARY_ADDRESS',
  SET_PRIMARY_PAYMENT_METHOD = 'Account.SET_PRIMARY_PAYMENT_METHOD',
  START_GIFT_RETURN = 'Account.START_GIFT_RETURN',
  START_RESELL = 'Account.START_RESELL',
  START_RETURN = 'Account.START_RETURN',
  SUBMIT_CREDIT_CARD = 'Account.SUBMIT_CREDIT_CARD',
  TRACK_ORDER = 'Account.TRACK_ORDER',
  VIEW_ORDER_DETAILS = 'Account.VIEW_ORDER_DETAILS',
  VIEW_REFUND_METHOD = 'Account.VIEW_REFUND_METHOD',
  VIEW_SHIPPING_LABEL = 'Account.VIEW_SHIPPING_LABEL',
}

export default Account
